import React, { useState } from 'react';
import {
    Box,
} from '@chakra-ui/react';
import { useOktaAuth } from '@okta/okta-react';

import { Button } from '../Components/ui/button';

import ChangedOMC from '../Components/Layout/Utilities/ChangedOMC';
import { findRenderedDOMComponentWithTag } from 'react-dom/test-utils';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const approvalPath = '/api/approval/yamdu';
const testPath = '/api/approval/test';
const updatePath = '/api/omc/update';

// Does a test fetch from the graphql API
// async function fetchTest(accessToken) {
//     try {
//         const url = labkoatApiUrl + testPath;
//         const options = {
//             method: 'GET',
//             mode: 'cors',
//             headers: {
//                 'Cache-Control': 'no-cache',
//                 Authorization: `Bearer ${accessToken}`,
//             },
//         };
//         const res = await fetch(url, options);
//         return res.json();
//     } catch (err) {
//         console.log('Error');
//         console.log(err);
//         return null;
//     }
// }

function updateDiff({ action, omc, omcData, response }) {
    const { entityType, identifier } = omc;
    const fullItem = omcData[entityType].find((item) => {
        const cOmc = item.comparison || item.original;
        const found = cOmc.identifier.find((j) => {
            const r = j.identifierScope === identifier[0].identifierScope && j.identifierValue === identifier[0].identifierValue;
            return r;
        });
        return found;
    });
    console.log(response);
    console.log(fullItem);
}

function omcChanges(data) {
    const omcData = {};
    Object.keys(data).forEach((key) => {
        const items = data[key];
        const changedItems = [];
        items.forEach((item) => {
            let action = null;
            let identifier = null;
            if (!item.comparison) {
                action = 'Remove';
                identifier = item.original.identifier;
            }
            if (!item.original) {
                action = 'Create';
                identifier = item.comparison.identifier;
            }
            if (item.diff && item.comparison) {
                action = 'Update';
                identifier = item.comparison.identifier;
            }
            if (action) changedItems.push({
                action,
                status: true,
                item,
                identifier,
            });
        });
        if (changedItems.length) omcData[key] = changedItems;
    });
    return omcData;
}

async function fetchAction(accessToken, omc, method) {
    try {
        const params = '?project=yamdu';
        const url = labkoatApiUrl + updatePath + params;
        console.log(url);
        const options = {
            method,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(omc),
        };
        return fetch(url, options);
    } catch (err) {
        console.log('Error');
        console.log(err);
        return null;
    }
}

async function fetchEvents(accessToken) {
    try {
        const url = labkoatApiUrl + approvalPath;

        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const res = await fetch(url, options);
        return res.json();
    } catch (err) {
        console.log('Error');
        console.log(err);
        return null;
    }
}

function Approval() {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const [isLoading, setIsLoading] = useState(false);
    const [omcData, setOmcData] = useState(null);

    const handleUpdate = () => {
        setIsLoading(true);
        fetchEvents(accessToken)
            .then((data) => {
                console.log(data);
                const changedData = omcChanges(data); // Filter out only entities that have changed
                console.log(changedData);
                setOmcData(changedData);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const handleAction = async (change) => {
        console.log(change);

        // Track which items are to be updated or removed
        const fMam = {
            update: [],
            remove: [],
        };
        change.forEach((c) => {
            const { action, status, item } = c; // Is the item checked
            const key = action === 'Remove' ? 'remove' : 'update'; // Is the item to be removed or updated
            if (status) fMam[key].push(item?.comparison || item?.original); // If checked send as update
        });
        console.log(fMam);

        try {
            const updateRes = await fetchAction(accessToken, fMam.update, 'POST');
            const removeRes = await fetchAction(accessToken, fMam.remove, 'DELETE');
            console.log(removeRes.status, updateRes.status);
            if (removeRes.status === 200 && updateRes.status === 200) {
                console.log('Successful update');

                // Remove publisnhed entities from the display list
                const success = [...fMam.update, ...fMam.remove];
                success.forEach((ent) => {
                    const { entityType, identifier } = ent;
                    omcData[entityType] = omcData[entityType].filter((item) => (
                        identifier[0].identifierValue !== item.identifier[0].identifierValue
                    ));
                    if (!omcData[entityType].length) delete omcData[entityType];
                });
                console.log(omcData);
                setOmcData({ ...omcData });
            }
        } catch (err) {
            console.log(err);
        }
    };

    // If the user is not yet authorized or the vocabulary is not loaded return the spinner
    return (!oktaAuth.isAuthenticated)
        ? (
            <Box>Not Authorized</Box>
        )
        : (
            <Box m={4}>
                <Box>
                    <Button
                        loading={isLoading}
                        colorPalette="blue"
                        variant="surface"
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                </Box>
                {omcData
                    ? (
                        <>
                            {Object.keys(omcData).map((key) => (
                                <Box key={key}>
                                    <ChangedOMC
                                        entityType={key}
                                        omcDiff={omcData[key]}
                                        handleAction={handleAction}
                                    />
                                </Box>
                            ))}
                        </>
                    )
                    : null}
            </Box>
        );
}

export default Approval;
