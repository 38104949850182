import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Flex,
    Icon,
    Spacer,
    Button,
    IconButton,
    Separator,
    Input, Group, InputAddon,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';

const genRanHex = ((size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join(''));

// Validate the new label is a valid entry
const validLabel = ((label) => label !== ''); // Cannot be empty string

// Describe operations required for each array type
const entryTypes = {
    altLabel: {
        validate: validLabel, // Function to validate the new label
        identifier: genRanHex, // Function for generating a new identifier
        placeholder: 'Add new label',
        nodeType: 'skosxl:Label',
        identifierPrefix: 'vmc:xl-en',
    },
};

const arrayItems = (items, removeItem) => items.map((item) => (
    <Flex key={item.id}>
        <Group mt={2}>
            <IconButton
                bg="blue.300"
                aria-label="Delete"
                size="xs"
                onClick={((e) => removeItem(e, item))}
            >
                <CloseIcon />
            </IconButton>
            <Box>
                {item.value}
            </Box>
        </Group>
    </Flex>
));

function ArrayEditor({
    data,
    colDef,
    onValueChange,
    stopEditing,
}) {
    const { field } = colDef; // The field being edited in the table row
    const placeHolder = entryTypes[field].placeholder; // Placeholder for the input field
    const nodeType = entryTypes[field].nodeType; // The type of node being created
    const identifierPrefix = entryTypes[field].identifierPrefix; // Prepended onto the generated identifier
    const language = 'en'; // Default language, this may become selectable in the future
    const [entryField, setEntryField] = useState(field);
    const [initialValue, setInitialValue] = useState(data[field]); // The row value prior to any editing
    const [cellValue, setCellValue] = useState(data[field]); // The new row value as it is being edited
    const [newValue, setNewValue] = useState(''); // The input value as it is being typed
    const [ready, setReady] = useState(false);
    const refContainer = useRef(null);

    useEffect(() => {
        refContainer.current.focus();
        setReady(true);
    }, []);

    const removeItem = ((event, item) => {
        console.log(cellValue);
        const removeId = item.id;
        const updatedArray = cellValue.filter((i) => i.id !== removeId);
        console.log(updatedArray);
        setCellValue(updatedArray);
    });

    const addItem = ((e, inputId) => {
        if (!entryTypes[entryField].validate(newValue)) return; // Check for valid entry

        const newId = `${identifierPrefix}-${entryTypes[entryField].identifier(6)}`; // Create a new identifier
        const newItem = {
            id: newId,
            language,
            // type: nodeType,
            value: newValue,
        };
        console.log(newItem);
        const updatedArray = [...cellValue, newItem];
        setCellValue(updatedArray);
        setNewValue(''); // Reset the input value
        document.getElementById(inputId).value = ''; // Clear input field
        console.log('Add new item');
    });

    const confirm = (() => {
        console.log('Confirm Changes');
        console.log(cellValue);
        onValueChange(cellValue);
        stopEditing();
    });

    const cancel = (() => {
        console.log('Cancel Changes');
        onValueChange(initialValue); // Respond with the initial value, no changes
        stopEditing();
    });

    return (
        <Box
            p="3"
            bg="blue.50"
            borderWidth="1px"
            borderRadius="lg"
            ref={refContainer}
            // tabIndex={1} // important - without this the key presses won't be caught
        >
            {arrayItems(cellValue, removeItem)}
            <Separator m="2" />
            <Flex>
                <Group>
                    <InputAddon
                        variant="ghost"
                        p={0}
                    >
                        <IconButton
                            bg="cyan.300"
                            aria-label="Add"
                            size="xs"
                            onClick={(e) => addItem(e, 'ag-grid-array-input')}
                        >
                            <AddIcon />
                        </IconButton>
                    </InputAddon>
                    <Input
                        size="sm"
                        id="ag-grid-array-input"
                        onChange={(e) => setNewValue(e.target.value)}
                        placeholder={placeHolder}
                    />
                </Group>
            </Flex>
            <Separator m="2" />
            <Flex>
                <Button size="sm" colorPalette="green" onClick={confirm}>
                    Confirm
                </Button>
                <Spacer />
                <Button size="sm" colorPalette="orange" onClick={cancel}>
                    Cancel
                </Button>
            </Flex>
        </Box>
    );
}

export default ArrayEditor;
