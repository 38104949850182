export const displayFrame = {
    borderRadius: 'lg',
    borderColor: '#DDD',
    borderWidth: '1px',
    m: 1,
};

export const sideBarHeader = {
    borderTopRadius: 'lg',
    borderColor: '#DDD',
    borderWidth: '1px',
    m: 1,
    mb: 0,
    p: 2,
};

export const sideBarBody = {
    borderBottomRadius: 'lg',
    borderColor: '#DDD',
    background: '#ffffff',
    borderWidth: '1px',
    borderTopWidth: '0px',
    m: 1,
    mt: 0,
    p: 2,
};
