import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Flex,
    Button,
    Badge,
    AbsoluteCenter,
} from '@chakra-ui/react';

import {
    AccordionRoot,
    AccordionItemTrigger,
    AccordionItem,
    AccordionItemContent,
} from '../../ui/accordion';
import { Checkbox } from '../../ui/checkbox';

import OutputOMC from './OutputOMC';

const keyId = () => (Math.floor(Math.random() * 65536)).toString(16);

function TriggerCheckBox({
    change,
}) {
    const [checked, setChecked] = useState(change.status);
    const { item } = change;
    const name = item.comparison?.name || item.original?.name || 'Unknown';
    change.status = checked; // Keep the status in sync with the checkbox
    return (
        <Box position="relative" borderTop="sm" borderColor="#4299E1">
            <AccordionItemTrigger indicatorPlacement="start">
                {name}
            </AccordionItemTrigger>
            <AbsoluteCenter axis="vertical" insetEnd="0">
                <Badge size="lg">
                    {change.action}
                </Badge>
                <Checkbox
                    variant="subtle"
                    colorPalette="blue"
                    size="lg"
                    checked={checked}
                    onCheckedChange={(e) => setChecked(!!e.checked)}
                />
            </AbsoluteCenter>
        </Box>
    );
}

function SaveState({ change }) {
    const { item } = change;
    const name = item.comparison?.name || item.original?.name || 'Unknown';
    const reactKey = `${name}-${keyId()}`;
    return (
        <AccordionItem key={reactKey} value={reactKey}>
            <TriggerCheckBox
                change={change}
            />
            <AccordionItemContent>
                <OutputOMC
                    omcLs={item.original}
                    omcRs={item.comparison}
                    diff={item.diff}
                    embed
                />
            </AccordionItemContent>
        </AccordionItem>
    );
}

function ChangedOMC({
    entityType = 'Missing',
    omcDiff = [],
    handleAction = () => { },
}) {
    // Count the number and type of changes
    const update = omcDiff.reduce((count, change) => (change.action === 'Update' ? count + 1 : count), 0);
    const remove = omcDiff.reduce((count, change) => (change.action === 'Remove' ? count + 1 : count), 0);
    const create = omcDiff.reduce((count, change) => (change.action === 'Create' ? count + 1 : count), 0);
    const entityStatus = `Changed: ${update} | Removed: ${remove} | Created: ${create}`;
    const entityTitle = `${entityType}`;
    const reactKey = `${entityType}-${keyId()}`;

    // Currently not displaying removed items, need to decide what we want to do with those?
    return (
        <AccordionRoot multiple collapsible variant="plain">
            <AccordionItem key={reactKey}>
                <Box position="relative">
                    <AccordionItemTrigger indicatorPlacement="start">
                        <Flex w="100%" gap={4}>
                            <Box w="50%">
                                {entityTitle}
                            </Box>
                            <Box float="right">
                                {entityStatus}
                            </Box>
                        </Flex>
                    </AccordionItemTrigger>
                    <AbsoluteCenter axis="vertical" insetEnd="0">
                        <Button
                            variant="subtle"
                            colorPalette="blue"
                            size="sm"
                            name="Publish"
                            buttonTitle="Publish"
                            onClick={() => handleAction(omcDiff)}
                        >
                            Publish
                        </Button>
                    </AbsoluteCenter>
                </Box>
                <AccordionItemContent pl={8}>
                    {omcDiff.map((change) => (
                        <SaveState
                            change={change}
                        />
                    ))}
                </AccordionItemContent>
            </AccordionItem>
        </AccordionRoot>
    );
}

ChangedOMC.propTypes = {
    entityType: PropTypes.string,
    omcDiff: PropTypes.arrayOf(PropTypes.shape),
    handleAction: PropTypes.func,
};

export default ChangedOMC;
