/**
 * Order the properties of an object based on a model
 */

const preferredOrder = [
    'schemaVersion',
    'entityType',
    'identifier',
    'name',
    'description',
];

export default function orderOMC(omc) {
    if (!omc) return null; // Return null if the object is null

    const ordered = {};

    // Put the known keys in the preferred order
    preferredOrder.forEach((key) => {
        if (key in omc) {
            ordered[key] = omc[key];
        }
    });

    // Put the unknown keys in the order they were found
    Object.keys(omc).forEach((key) => {
        if (!preferredOrder.includes(key)) {
            ordered[key] = omc[key];
        }
    });
    return ordered;
}
