import React from 'react';

import {
    Menu as ChakraMenu,
    Portal,
    Box,
} from '@chakra-ui/react';

const MenuContent = React.forwardRef((props, ref) => {
    const { portalled = true, portalRef, ...rest } = props;
    return (
        <Portal disabled={!portalled} container={portalRef}>
            <ChakraMenu.Positioner>
                <Box left={rest.position.x} top={rest.position.y} position="absolute">
                    <ChakraMenu.Content ref={ref} {...rest} />
                </Box>
            </ChakraMenu.Positioner>
        </Portal>
    );
});

function ContextMenu({ contextMenu }) {
    if (!contextMenu || !contextMenu.items) return null;

    const {
        items = [],
        position = { x: 0, y: 0 },
        onClose = () => {},
        onClick = () => {},
        node = null,
    } = contextMenu;

    return (
        <ChakraMenu.Root
            open
            onSelect={({ value }) => {
                onClick(value, node);
                onClose();
            }}
            onInteractOutside={onClose}
            onPointerLeave={onClose}
        >
            <MenuContent position={position}>
                {items.map((item) => (
                    <ChakraMenu.Item
                        key={item.key}
                        value={item.action}
                    >
                        {item.label}
                    </ChakraMenu.Item>
                ))}
            </MenuContent>
        </ChakraMenu.Root>
    );
}

export default ContextMenu;
