import React, {
    createRef,
    useCallback, useContext,
    useLayoutEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Flex, Portal,
} from '@chakra-ui/react';
import { Toaster, toaster } from '../../ui/toaster';

import D3ZoomWrapper from '../D3ZoomWrapper';
import OmcJsonTreeDiagram from './OmcJsonTreeDiagram';
import OmcJsonSideBar from './OmcJsonSideBar';
import OmcJsonTable from './OmcJsonTable';

import { useSkosDictionary } from '../../../hooks/SkosDictionaryContext';
import { OmcDictionaryContext, useOmcDictionary, useOmcJsonRoot } from '../../../hooks/OmcDictionaryContext';
import useWindowInnerSize from '../../../hooks/useWindowInnerSize.mjs';
import ContextMenu from '../ContextMenu';
import { updateOmcJsonD3Tree } from '../../../services/vocabulary/omcJsonD3Tree.mjs';
import {
    createD3Hierarchy,
    checkOpenChildren,
    setOpenChildren,
    omcD3id,
    skosD3id,
} from '../../../services/vocabulary/d3Utilities.mjs';
import useTraceUpdate from '../../../hooks/useTraceUpdate';

const treeId = 'omcJson';
const graphTableRatio = 0.5; // The ratio of size for the top display to the bottom

function OmcJsonEditorContainer(props) {
    const {
        accessToken = null, // API access token
    } = props;
    // console.log('Fired the OmcJsonEditorContainer');
    // useTraceUpdate(props);

    const containerRef = createRef(); // Reference for container that holds the chart and table
    const d3ZoomRef = createRef(); // Reference for the zoom wrapper
    const { innerWidth, innerHeight } = useWindowInnerSize(); // Dimensions of container and event listener for changes
    const [d3ContainerSize, setD3ContainerSize] = useState({ width: 0, height: 0 }); // D3 container dimensions
    const [tableContainerSize, setTableContainerSize] = useState({ width: 0, height: 0 }); // Table container dimensions
    const [zoomPosition, setZoomPosition] = useState({ k: 1, x: 120, y: d3ContainerSize.height / 2 }); // Keeps a copy of the current zoom position;

    const [contextMenu, setContextMenu] = useState({}); // Context menu to be displayed
    const [sideBarContent, setSideBarContent] = useState(null); // Indicates content to be displayed in the sidebar
    const [forceSideBarRender, setForceSideBarRender] = useState(0); // Table has been updated, re-render sidebar
    const [forceTableRender, setForceTableRender] = useState(0); // Tree has been updated, re-render table
    const [dragNodeId, setDragNodeId] = useState({ nodeId: null, location: null }); // Function to add a node to the tree

    const skosDictionary = useSkosDictionary();
    const omcDictionary = useOmcDictionary();
    const root = useOmcJsonRoot();
    const { updateRoot } = useContext(OmcDictionaryContext);

    // Set the sizes for the chart and table containers
    useLayoutEffect(() => {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const cHeight = (innerHeight - containerTop); // Padding and margin adjustments
        const { width } = d3ZoomRef.current.getBoundingClientRect();
        setD3ContainerSize({ width, height: cHeight * graphTableRatio });
        setTableContainerSize({ width: innerWidth, height: cHeight * (1 - graphTableRatio) });
    }, [innerWidth, innerHeight]);

    // Force update of the table because some action was taken in the graph
    const updateTable = useCallback(() => {
        setForceTableRender((a) => a + 1);
    }, []);

    // Add a draggable node to the tree display
    const updateDragNode = useCallback((nodeId, location = null) => {
        console.log(`Change the drag node ${nodeId}`);
        setDragNodeId({ nodeId, location });
    }, []);

    // The side-bar needs to be updated because data in the table has changed
    const agGridUpdateSideBar = useCallback(() => {
        setForceSideBarRender((a) => a + 1);
    }, []);

    // Update the sidebar content based on the selected node
    const updateSideBar = useCallback((omcId) => {
        if (omcId === undefined) return; // No need to update the sidebar if there is no change to content
        setSideBarContent(omcId);
    }, []);

    // Display the context menu on right-click
    const displayContextMenu = useCallback((params) => {
        const { event } = params;
        setContextMenu({
            ...params,
            position: {
                x: event.clientX,
                y: event.clientY,
            },
            onClose: (() => {
                console.log('Close the context menu');
                setContextMenu({});
            }),
        });
    }, []);

    // Display a message to the user using the toast
    const displayMessage = useCallback((message) => {
        toaster.create({
            title: message.title,
            description: message.description,
            type: message.status,
            duration: 5000,
            isClosable: true,
        });
    }, []);

    // Carry out updates to the chart after interactions and updates to data
    const updateChart = useCallback(async ({
        error = null, // Error message
        warning = null, // Warning message
        action = null, // The database action to be taken
        openChildId = {}, // The id of the child to be opened or closed
    }) => {
        if (error) { // If an error has occurred, display the message and return
            displayMessage(error);
            return null;
        }

        if (warning) displayMessage(warning); // If a warning has occurred, display the message, but execute the action

        const updateResponse = action
            ? await omcDictionary.update(action, accessToken) // Update the database
            : null;
        const openChildren = checkOpenChildren(root, openChildId); // Record the open children
        const omcChart = updateOmcJsonD3Tree(openChildren, omcDictionary); // Create the d3 hierarch
        const d3Hierarchy = createD3Hierarchy(omcChart, skosD3id); // Create a new d3 hierarchy
        const updatedRoot = setOpenChildren(d3Hierarchy, openChildren); // Set the previously open children
        updateRoot(omcDictionary, updatedRoot); // Push the updated root to the Context
        return updateResponse;
    }, [root]);

    return (
        <>
            <Toaster />
            <Flex direction="column" ref={containerRef}>
                <Flex direction="row" maxH={d3ContainerSize.height}>
                    <D3ZoomWrapper
                        d3ContainerSize={d3ContainerSize}
                        setZoomPosition={setZoomPosition}
                        treeId={treeId}
                    >
                        <Box ref={d3ZoomRef}>
                            <OmcJsonTreeDiagram
                                containerDimensions={d3ContainerSize}
                                treeId={treeId}
                                updateSideBar={updateSideBar}
                                updateTable={updateTable}
                                updateChart={updateChart}
                                displayMessage={displayMessage}
                                displayContextMenu={displayContextMenu}
                                dragNodeId={dragNodeId}
                                updateDragNode={updateDragNode}
                                zoomPosition={zoomPosition}
                                triggerRender={forceSideBarRender}
                                agGridUpdateSideBar={agGridUpdateSideBar}
                            />
                        </Box>
                    </D3ZoomWrapper>
                    <OmcJsonSideBar
                        sideBarContent={sideBarContent}
                        sideBarContainerSize={d3ContainerSize}
                        forceSideBarRender={forceSideBarRender}
                    />
                </Flex>
                <OmcJsonTable
                    skosDictionary={skosDictionary}
                    omcDictionary={omcDictionary}
                    changeDragNode={updateDragNode}
                    updateTable={updateTable}
                    updateChart={updateChart}
                    updateSideBar={agGridUpdateSideBar}
                    forceTableRender={forceTableRender}
                    tableContainerSize={tableContainerSize}
                    displayMessage={displayMessage}
                />
            </Flex>
            <ContextMenu contextMenu={contextMenu} />
        </>
    );
}

OmcJsonEditorContainer.propTypes = {
    accessToken: PropTypes.string, // The id of a dictionary entity to be displayed
};

export default OmcJsonEditorContainer;
