import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import {
    Box,
    Table,
    // Table.Header,
    // Table.Row,
    // Th,
    // Tbody,
    Center,
    Spinner, Button,
    // Divider,
} from '@chakra-ui/react';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const directoryPath = '/api/okta/directory';
const securityPath = '/api/okta/directory/security';

function DirectoryList({ list }) {
    const dirKey = ((i) => `idpTable-${i}`);

    const directoryNames = list.map((user, i) => (
        <Table.Row key={dirKey(i)}>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell>{user.title}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.phone}</Table.Cell>
            <Table.Cell>{user.role}</Table.Cell>
            <Table.Cell>{user.oktaId}</Table.Cell>
        </Table.Row>
    ));

    return (
        <Table variant="simple">
            <Table.Header>
                <Table.Row>
                    <Table.ColumnHeader>Name</Table.ColumnHeader>
                    <Table.ColumnHeader>Job Title</Table.ColumnHeader>
                    <Table.ColumnHeader>Email</Table.ColumnHeader>
                    <Table.ColumnHeader>Mobile</Table.ColumnHeader>
                    <Table.ColumnHeader>Role</Table.ColumnHeader>
                    <Table.ColumnHeader>Okta Sub</Table.ColumnHeader>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {directoryNames}
            </Table.Body>
        </Table>
    );
}

DirectoryList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};
DirectoryList.defaultProps = { list: [] };

const updateSecurity = (async (accessToken) => {
    const url = labkoatApiUrl + securityPath;
    console.log(`Ping API: ${url}`);
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    try {
        const res = await fetch(url, options);
        if (res.status === 200) {
            console.log('Successful security update');
        }
    } catch (e) {
        console.log(e.message);
    }
});

const participantOmc = (list) => {
    const directory = [];
    list.forEach((p) => {
        if (p.sTable.RowucturalCharacteristics.sTable.RowucturalType === 'person') {
            const { Person } = p.sTable.RowucturalCharacteristics;
            const { Role } = p.functionalCharacteristics;
            const { name } = Person;
            const email = Person?.contact?.email?.business || 'N/A';
            const phone = Person?.contact?.telephone?.business || 'N/A';
            const oktaIdentifier = Person.identifier.filter((id) => id.identifierScope === 'okta');
            const oktaId = oktaIdentifier.length ? oktaIdentifier[0].identifierValue : 'N/A';
            const roles = Role.map((r) => r.roleType);
            directory.push({
                name,
                email,
                phone,
                oktaId,
                role: roles.join(', '),
            });
        }
    });
    return directory;
};

async function fetchDirectory(accessToken) {
    try {
        const url = labkoatApiUrl + directoryPath;
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const res = await fetch(url, options);
        const dirData = await res.json();
        const list = participantOmc(dirData.participants);
        console.log(list);
        return list;
    } catch (err) {
        console.log('Error');
        console.log(err);
        return [];
    }
}

function Directory() {
    const [dirList, updateDirList] = useState([]);
    const [isLoading, updateLoading] = useState(true);
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();

    useEffect(() => {
        fetchDirectory(accessToken)
            .then((data) => {
                updateDirList(data);
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                updateLoading(false);
            });
    }, []);

    return isLoading
        ? (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        )
        : (
            <Box>
                <Box>
                    <DirectoryList list={dirList} />
                </Box>
                <Button m={5} onClick={() => updateSecurity(accessToken)}>Update Security</Button>
            </Box>
        );
}

export default Directory;
