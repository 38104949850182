/*
Request a single OMC entity from the LabKoat API using the provided parameters.
The function returns the OMC entity as a JavaScript object.
*/

import { OmcModel } from './omcUtil.mjs';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const apiPath = '/api/omc/entity';

/**
 * Request a single OMC entity from the LabKoat API using the provided parameters.
 * @param params.identifierScope (string) - The scope of the identifier
 * @param params.identifierValue (string) - The value of the identifier
 * @param params.currentProject (string) - The current project identifier
 * @param accessToken
 * @returns {Promise<(*)|null>}
 */

export default async function getEntity(params, accessToken) {
    const {
        identifierScope,
        identifierValue,
        currentProject: project,
    } = params;

    console.log(identifierScope, identifierValue, project);
    const url = `${labkoatApiUrl}${apiPath}?project=${project}&identifierScope=${identifierScope}&identifierValue=${identifierValue}`;
    console.log(url);
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };

    try {
        const res = await fetch(url, options);

        if (res.status !== 200) {
            console.log(`Fetch Error: ${res.status} - ${res.statusText}`);
            console.log(await res.json());
            console.log(`${identifierValue} - Not available`);
            return null;
        }
        const omc = await res.json();
        return OmcModel(omc);
    } catch (err) {
        console.log(err);
        return null;
    }
}
