import React from 'react';
import { Box } from '@chakra-ui/react';
// import useTraceUpdate from '../../hooks/useTraceUpdate';

function D3Wrapper(props) {
    const {
        treeId, // The DOM #id for the container
        d3ContainerSize,
        children,
    } = props;

    return (
        <Box
            mr={1}
            h={d3ContainerSize.height}
            w="100%"
        >
            <svg
                id={treeId}
                width="100%"
                height={d3ContainerSize.height}
            >
                <g id={`${treeId}-container`}>
                    <g className="treeLink" />
                    <g className="treeNode" />
                </g>
            </svg>
            {children}
        </Box>
    );
}

export default React.memo(D3Wrapper);
