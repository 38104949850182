/**
 * Container for holding the d3 chart, sidebar and table for the SKOS Editor
 */

import React, {
    useState,
    useCallback, createRef, useContext, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Flex,
} from '@chakra-ui/react';
import { Toaster, toaster } from '../../ui/toaster';

import D3ZoomWrapper from '../D3ZoomWrapper';
import SkosTable from './SkosTable';
import { updateD3SkosTree } from '../../../services/vocabulary/skosD3Tree.mjs';
import SkosTreeDiagram from './SkosTreeDiagram';
import SkosSideBar from './SkosSideBar';
import { SkosDictionaryContext, useSkosDictionary, useSkosRoot } from '../../../hooks/SkosDictionaryContext';
import useWindowInnerSize from '../../../hooks/useWindowInnerSize.mjs';
import {
    checkOpenChildren,
    createD3Hierarchy,
    skosD3id,
    setOpenChildren,
} from '../../../services/vocabulary/d3Utilities.mjs';

import ContextMenu from '../ContextMenu';

const treeId = 'skosTree';
const graphTableRatio = 0.5; // The ratio of size for the top display to the bottom

function SkosEditorContainer(
    {
        accessToken = null, // API access token,
    },
) {
    const containerRef = createRef(); // Reference for container that holds the chart and table, provide height
    const d3ZoomRef = createRef(); // Reference for the zoom wrapper, provide width
    const {
        innerWidth,
        innerHeight,
    } = useWindowInnerSize(); // Dimensions of container
    const [d3ContainerSize, setD3ContainerSize] = useState({
        width: 0,
        height: 0,
    }); // D3 container dimensions
    const [tableContainerSize, setTableContainerSize] = useState({
        width: 0,
        height: 0,
    }); // Table container dimensions
    const [zoomPosition, setZoomPosition] = useState({
        k: 1,
        x: 120,
        y: d3ContainerSize.height / 2,
    }); // Keeps a copy of the current zoom position;
    const [contextMenu, setContextMenu] = useState({}); // Context menu to be displayed
    const [dragNodeId, setDragNodeId] = useState({
        nodeId: null,
        location: null,
    }); // Function to add a node to the tree
    const [selectedScheme, setSelectedScheme] = useState([null]); // Array of scheme identifiers to display in table
    const [sideBarContent, setSideBarContent] = useState(null); // Contents to display in the sidebar

    const skosDictionary = useSkosDictionary();
    const root = useSkosRoot();
    const { skosEditor, updateRoot } = useContext(SkosDictionaryContext);

    // const toast = useToast();

    // Set the sizes for the chart and table containers
    useLayoutEffect(() => {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const cHeight = (innerHeight - containerTop); // Padding and margin adjustments
        const { width } = d3ZoomRef.current.getBoundingClientRect();
        setD3ContainerSize({
            width,
            height: cHeight * graphTableRatio,
        });
        setTableContainerSize({
            width: innerWidth,
            height: cHeight * (1 - graphTableRatio),
        });
    }, [innerWidth, innerHeight]);

    // Set which scheme or schemes to display in the table
    const updateTable = useCallback((schemeId) => {
        // If the schemeId is an array or string, use it, if null use existing but force re-render of table
        const spreadScheme = Array.isArray(schemeId) ? schemeId.filter((n) => n) : [schemeId];
        const requestedScheme = [null, ...spreadScheme]; // Return concepts not in a scheme and in the requested schemes
        setSelectedScheme(requestedScheme); // Request a new scheme to be displayed
    }, []);

    // Add a draggable node to the tree display
    const updateDragNode = useCallback((nodeId, location = null) => {
        console.log(`Change the drag node: ${nodeId}`);
        setDragNodeId({ nodeId, location });
    }, []);

    // Update the sidebar content based on the selected node
    const updateSideBar = useCallback((omcId) => {
        if (omcId === undefined) return; // No need to update the sidebar if there is no change to content
        setSideBarContent(omcId);
    }, []);

    // Display the context menu on right-click
    const displayContextMenu = useCallback((params) => {
        const { event } = params;
        setContextMenu({
            ...params,
            position: {
                x: event.clientX,
                y: event.clientY,
            },
            onClose: () => setContextMenu({ }),
        });
    }, []);

    // Use a Toast to display messages to the user
    const displayMessage = useCallback((message) => {
        toaster.create({
            title: message.title,
            description: message.description,
            type: message.status,
            duration: 5000,
            isClosable: true,
        });
    }, []);

    const updateChart = useCallback(async ({
        error = null, // Error message
        warning = null, // Warning message
        action = null,
        openChildId = {},
    }) => {
        if (error) { // If an error has occurred, display the message and return
            displayMessage(error);
            return null;
        }

        if (warning) displayMessage(warning); // If a warning has occurred, display the message, but execute the action

        const updateResponse = action
            ? await skosDictionary.update(action, accessToken) // Update the database
            : null;
        const openChildren = checkOpenChildren(root, openChildId); // Record the open children
        const omcChart = updateD3SkosTree(openChildren, skosDictionary); // Create the d3 hierarchy
        const d3Hierarchy = createD3Hierarchy(omcChart, skosD3id); // Create a new d3 hierarchy
        const updatedRoot = setOpenChildren(d3Hierarchy, openChildren); // Set the previously open children
        updateRoot(skosDictionary, updatedRoot); // Push the updated root to the Context
        return updateResponse;
    }, [root]);

    return (
        <>
            <Toaster />
            <Flex direction="column" ref={containerRef}>
                <Flex direction="row" maxH={d3ContainerSize.height}>
                    <D3ZoomWrapper
                        d3ContainerSize={d3ContainerSize}
                        setZoomPosition={setZoomPosition}
                        treeId={treeId}
                    >
                        <Box ref={d3ZoomRef}>
                            <SkosTreeDiagram
                                updateChart={updateChart}
                                updateSideBar={updateSideBar}
                                updateTable={updateTable}
                                updateDragNode={updateDragNode}
                                displayMessage={displayMessage}
                                displayContextMenu={displayContextMenu}
                                selectedScheme={selectedScheme}
                                treeId={treeId}
                                dragNodeId={dragNodeId}
                                containerDimensions={d3ContainerSize}
                                zoomPosition={zoomPosition}
                            />
                        </Box>
                    </D3ZoomWrapper>
                    <SkosSideBar
                        sideBarContent={sideBarContent}
                    />
                </Flex>
                <SkosTable
                    updateChart={updateChart}
                    updateTable={updateTable}
                    updateDragNode={updateDragNode}
                    updateSideBar={updateSideBar}
                    displayMessage={displayMessage}
                    selectedScheme={selectedScheme}
                    containerDimensions={tableContainerSize}
                />
            </Flex>
            <ContextMenu contextMenu={contextMenu} />
        </>
    );
}

SkosEditorContainer.propTypes = {
    accessToken: PropTypes.string, // The id of a dictionary entity to be displayed
};

export default SkosEditorContainer;
