import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Text,
    Flex,
} from '@chakra-ui/react';

import { MdDataObject, MdDataArray } from 'react-icons/md';

import {
    AccordionRoot,
    AccordionItemTrigger,
    AccordionItem,
    AccordionItemContent,
} from '../../ui/accordion';

import orderOMC from '../../../services/compare/orderOMC.mjs';

// Colors for highlighting changes
const highlightColors = {
    $update: 'orange',
    $remove: 'red',
    $create: '#00ff00',
    $default: 'black',
};

const isPlainObject = (obj) => obj && typeof obj === 'object' && obj.constructor === Object;
const keyId = () => (Math.floor(Math.random() * 65536)).toString(16);

// When a whole object is removed, the diff object will contain a $remove for each key
const removedSymbol = ((value) => {
    if (Array.isArray(value)) return '[ ]';
    if (isPlainObject(value)) return '{ }';
    return value;
});

const removedObject = (item) => Object.keys(item).reduce((r, o) => (
    {
        remove: { ...r.remove, [o]: { $remove: true } },
        output: { ...r.output, [o]: removedSymbol(item[o]) },
    }
), { output: {}, remove: {} });

// Format the value for display, adding quotes for strings and converting booleans to strings
const formatValue = ((value) => {
    if (typeof value === 'string') return `"${value}"`;
    if (typeof value === 'boolean') return JSON.stringify(value);
    if (value === null) return 'null';
    return value;
});

// Create a diff object for a when new object has been added to an array
const createDiff = ((item) => (
    Object.keys(item).reduce((obj, key) => (
        { ...obj, [key]: { $create: item[key] } }
    ), {})
));

function contentLine(title, value, highlight) {
    const highlightType = highlight ? Object.keys(highlight)[0] : '$default';
    const highlightColor = highlightColors[highlightType] || 'blue';

    let content = (
        <>
            <b>{`${title ? `${title}: ` : ''}`}</b>
            {formatValue(value)}
        </>
    );
    if (highlightType === '$remove') content = (<strike>{content}</strike>); // Strikethrough removed content

    const reactKey = `${value}-${keyId()}`;
    return (
        <Text
            key={reactKey}
            color={highlightColor}
            style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
        >
            {content}
        </Text>
    );
}

function JsonProperty(name, value, highlight) {
    const reactKey = `${name}-${keyId()}`;
    return (
        <AccordionItem key={reactKey} value={reactKey}>
            {contentLine(name, value, highlight)}
        </AccordionItem>
    );
}

function RemovedKey(key, icon) {
    const reactKey = `${key}-${keyId()}`;
    return (
        <AccordionItem key={reactKey} value={reactKey}>
            <Flex alignItems="center" gap="4" color={highlightColors.$remove}>
                <Box p="5px">
                    {icon}
                </Box>
                <Box>
                    <strike>
                        <b>
                            {key}
                        </b>
                    </strike>
                </Box>
            </Flex>
        </AccordionItem>
    );
}

function jsonEntity(entity, omcLs = {}, diff = null) {
    const obj = { ...omcLs, ...entity };
    const keys = Object.keys(obj);
    return keys.map((key) => {
        const highlight = (diff && diff[key]) ? diff[key] : null;

        // If the property value is a JS object, deal with the specific type of object
        if (typeof obj[key] === 'object') {
            // Handle null values
            if (obj[key] === null) return JsonProperty(key, null, highlight);

            // Pick the icon, array or plain object
            const icon = Array.isArray(obj[key]) ? (<MdDataArray />) : (<MdDataObject />);

            if (!entity[key] && highlight?.$remove) return RemovedKey(key, icon); // Key was removed

            const bgColor = highlight ? '#ffff00' : 'white'; // Highlight icon indicating changes
            let content;

            if (Array.isArray(obj[key])) {
                // Handle arrays of properties
                const removed = highlight ? highlight.$remove || [] : []; // Display for any removed items

                // Add any removed items to the list items in the array
                const updates = removed.map((item) => {
                    const { output, remove } = removedObject(item);
                    return {
                        index: '- ',
                        item: isPlainObject(item)
                            ? jsonEntity(output, {}, remove)
                            : contentLine(null, item, { $remove: true }),
                    };
                });

                // Add the remaining items in the array to the list
                const arrayItems = obj[key].map((item, index) => {
                    if (isPlainObject(item)) {
                        // Handle objects in the array, highlight if necessary if this is new object
                        if (!highlight?.$create) return { index, item: jsonEntity(item) }; // Not a highlighted item
                        const itemTest = JSON.stringify(item);
                        const isCreate = highlight.$create.find((i) => JSON.stringify(i) === itemTest);
                        return !isCreate
                            ? { index, item: jsonEntity(item) } // Not a highlighted item
                            : { index, item: jsonEntity(item, {}, createDiff(item)) }; // Highlighted object
                    }
                    // Primitive value, highlight if necessary
                    const addHighlight = highlight?.$create.includes(item) ? { $create: true } : null;
                    return { index, item: contentLine(null, item, addHighlight) };
                });

                // Combine items that have been removed and the remaining items in the array
                const arrayContent = [
                    ...updates,
                    ...arrayItems,
                ];
                content = arrayContent.map((item) => {
                    const reactKey = `${key}-${keyId()}`;
                    return (
                        <Flex key={reactKey} alignItems="center" gap="4" mb="2">
                            <Box>
                                <b>{`${item.index}:`}</b>
                            </Box>
                            <Box borderLeft="sm" borderRadius="5px" pl="2">
                                {item.item}
                            </Box>
                        </Flex>
                    );
                });
            } else {
                // Handle nested objects
                content = jsonEntity(obj[key], omcLs[key], highlight); // Recurse for nested object
            }

            const reactKey = `${key}-${keyId()}`;
            return (
                <AccordionItem key={reactKey} value={reactKey}>
                    <AccordionItemTrigger indicatorPlacement="start">
                        <Box bgColor={bgColor} p="5px" borderRadius="md">
                            {icon}
                        </Box>
                        {key}
                    </AccordionItemTrigger>
                    <AccordionItemContent pl={8}>
                        {content}
                    </AccordionItemContent>
                </AccordionItem>
            );
        }

        // If the property value is not an object or array, display as a key value pair
        return JsonProperty(key, obj[key], highlight);
    });
}

// Create a strip of images that together create a storyboard
function OutputOMC({
    omcLs = null,
    omcRs = null,
    diff = null,
    embed = false,
}) {
    // console.log('Diff', diff);
    // const orderedRs = omcRs ? orderOMC(omcRs) : null;
    const accordionLs = omcLs ? jsonEntity(orderOMC(omcLs)) : null;

    // If there is no left side, only display the right side or the right side with a diff
    let accordionRs = null;
    if (omcLs && omcRs) accordionRs = jsonEntity(orderOMC(omcRs), orderOMC(omcLs), diff);
    if (!omcLs && omcRs) accordionRs = jsonEntity(orderOMC(omcRs));

    // If this is embedded in another accordion, return without a root.
    return embed
        ? (
            <Flex gap={4}>
                <Box w="50%">
                    {accordionLs}
                </Box>
                <Box w="50%">
                    {accordionRs}
                </Box>
            </Flex>
        )
        : (
            <Flex gap={4}>
                <Box w="50%">
                    <AccordionRoot multiple collapsible variant="plain">
                        {accordionLs}
                    </AccordionRoot>
                </Box>
                <Box w="50%">
                    <AccordionRoot multiple collapsible variant="plain">
                        {accordionRs}
                    </AccordionRoot>
                </Box>
            </Flex>
        );
}

OutputOMC.propTypes = {
    omcLs: PropTypes.shape(),
    omcRs: PropTypes.shape(),
    diff: PropTypes.shape(),
    embed: PropTypes.bool,
};

export default OutputOMC;
