/**
 * API methods for POSTing or GETing data to the dictionary API
 */
import { docsControlledValue, functionalPropertyList } from './omcTable.mjs';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const apiPath = '/api/vocab';

export async function postUpdate(action, route, accessToken) {
    const url = `${labkoatApiUrl}${apiPath}/${route}`;
    const options = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(action),
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    try {
        const res = await fetch(url, options);
        if (res.status !== 200) {
            console.log(`Fetch Error: ${res.status} - ${res.statusText}`);
            return null;
        }
        return res.json();
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getDictionary(route, accessToken) {
    const url = `${labkoatApiUrl}${apiPath}/${route}`;
    console.log(url);
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    const res = await fetch(url, options);

    if (res.status !== 200) {
        console.log('Error fetching OMC dictionary');
        console.log(`${res.statusText} - ${res.status}`);
        return null;
    } else {
        return await res.json();
    }
}

/**
 * Download a SKOS encoding in either JSON-LD or TTL
 * @param format {string} Either 'ttl' or 'json'
 * @returns {Promise<void>}
 */
export async function downloadSkos(format) {
    const url = `${labkoatApiUrl}${apiPath}/skos/${format}`;
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            // Authorization: `Bearer ${accessToken}`, // This route not protected
        },
    };
    const res = await fetch(url, options);
    if (!res.ok) {
        console.log(`Error fetching export: ${res.status}`)
        return
    }

    const contentType = res.headers.get('content-type');
    let fileContent;
    if (contentType.includes('text/plain')) {
        const textRes = await res.text();
        fileContent = new Blob([textRes], {type: 'text/plain'})
    } else {
        const jsonRes = await res.json();
        fileContent = new Blob([JSON.stringify(jsonRes, null, 2)], {type: 'application/json'});
    }
    //anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(fileContent);
    element.download = `Media-Creation.${format}`;

    document.body.appendChild(element);
    element.click();
}

export async function downloadJSON(type, omcDict) {
    const functionalProperties = functionalPropertyList(omcDict);
    const fileContent = new Blob([JSON.stringify(functionalProperties, null, 2)], {type: 'application/json'});

    //anchor link, trigger a download
    const element = document.createElement('a');
    element.href = URL.createObjectURL(fileContent);
    element.download = `OMC-JSON-ControlledValues.json`;

    document.body.appendChild(element);
    element.click();
}

/**
 * Generate a markdown file for the OMC
 * @param omcDict
 * @param skosDict
 * @returns {Promise<void>}
 */

export async function downloadMd(omcDict, skosDict) {
    const functionalMd = docsControlledValue(omcDict, skosDict);
    const fileContent = new Blob([functionalMd], {type: 'text/markdown'});

    //anchor link, trigger a download
    const element = document.createElement('a');
    element.href = URL.createObjectURL(fileContent);
    element.download = `OMC-JSON-Markdown.md`;

    document.body.appendChild(element);
    element.click();
}
