import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'; // css styling for the OMC and vocabulary tree diagrams
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from 'next-themes';

import App from './App';

import customTheme from './style/customTheme';
import './style/index.css';
import './style/omcTreeDiagram.css';

console.log(`Environment: ${process.env.NODE_ENV}`);
console.log(`env file: ${process.env.REACT_APP_ENV}`);
console.log('Pushed: 9/19/2024 - 9:43PST');

ReactDOM.createRoot(document.getElementById('root')).render(
    <ChakraProvider value={customTheme}>
        <ThemeProvider attribute="class" disableTransitionOnChange>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
