/**
 * Configuration details for API'
 *
 * NO SECRETS
 *
 * oidc - oAuth login for the portal
 * labkoatMessaging - oAuth login for the messaging system
 * labkoatApiName - User friendly name for AWS API gateway
 *
 */

export default {
    oidc: {
        clientId: '0oa452q4cl4epJNGg696',
        // issuer: 'https://movielabs.okta.com/oauth2/default',
        issuer: 'https://movielabs.okta.com/oauth2/aus4zqd8ksuiL13Rl697', // Auth server Labkoat
        redirectUri: `${window.location.origin}/login/callback`,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: 'false',
        idps: [{
            type: 'GOOGLE',
            id: '0oa2rwap2qZg6By9B697',
        }],
        idpDisplay: 'SECONDARY',
    },
    labkoatMessaging: {
        baseUrl: 'https://c2-api.movielabs.com/auth/', // Base URL for authorization flow
        authRoute: 'auth', // Route for exchanging code for JWT
        issuerRoute: 'login?idp=okta', // Route to start oAuth flow
        redirectUri: `${window.location.origin}/openid`,
        source: 'labkoatMsg',
    },
    labkoatApiName: 'labkoatApiDev',
    projects: {
        europa: {
            label: 'Europa',
            db: 'Europa1',
            identifierScope: 'etc',
            root: {
                identifierScope: 'etc',
                identifierValue: 'cw-6zs2nlw2YKuNBX4',
            },
        },
        hsm: {
            label: 'HSM',
            db: 'POC6',
            identifierScope: 'labkoat',
            root: {
                identifierScope: 'labkoat',
                identifierValue: 'cw/hlDTz5s8IStbxWvdRVfSh',
            },
        },
        rebelFleet: {
            label: 'Rebel Fleet',
            db: 'RebelFleet',
            identifierScope: 'therebelfleet.co.nz-Europa',
            root: {
                identifierScope: 'therebelfleet.co.nz-Europa',
                identifierValue: 'cw-e84f2b8c6cc879b4314b2c83d871599c',
            },
        },
        yamdu: {
            label: 'Yamdu',
            db: 'Yamdu',
            identifierScope: 'com.yamdu.app',
            root: {
                identifierScope: 'com.yamdu.app',
                identifierValue: 'cw-6zs2nlw2YKuNBX4',
            },
        },
        nbc: {
            label: 'NBC/U',
            db: 'NBC',
            identifierScope: 'fast8',
            root: {
                identifierScope: 'fast8',
                identifierValue: '4:250933a5-fc16-4ecb-96c9-107da5d29f57:898',
            },
        },
        filmustage: {
            label: 'Film-U-Stage',
            db: 'filmustage',
            identifierScope: 'com.filmustage.app',
            root: {
                identifierScope: 'com.filmustage.app',
                identifierValue: 'com.filmustage.app.scene.bc841ee45ee411efbcd24a81c5642258',
            },
        },
    },
};
