/**
 * A set of default values for the attributes of the tree graph
 */

/**
 * Base description for node shapes
 * @type {{width: number, radius: number, height: number}}
 */

const pillShapeNode = {
    width: 200,
    height: 20,
    radius: 5,
};

export const fontHeight = 8; // Height of the font used in nodes (px)
export const nodeSpaceVertical = 26; // Vertical spacing between nodes, should greater than highest node shape
export const minSpaceHorizontal = 300; // The minimum horizontal space between nodes, should be greater than the width of the node shape

export const statusColor = {
    published: '#239B56',
    review: '#AF601A',
    proposed: '#2874A6',
    deprecated: '#B03A2E',
};

export const canDrop = {
    'omc:Root': [],
    'skos:ConceptScheme': ['omc:Root'],
    'skos:Concept': ['skos:ConceptScheme', 'skos:Concept'],
};

/**
 * Parameters for presenting nodes for each entity Type, style
 *
 * Colors: https://htmlcolorcodes.com/color-chart/
 */

export const nodeParams = {
    Default: {
        style: {
            color: '#555555',
            dragTarget: '#555555',
            dragColor: '#F1C40F',
            shape: {
                width: 20,
                height: 22,
                radius: 5,
            },
        },
        label: 'Default',
        displayProperty: ['name'],
        query: {
            allow: false,
        },
    },
    draggable: {
        style: {
            color: '#F6DDCC',
            expand: '#E59866',
            dragTarget: '#FF0033',
            dragColor: '#FF7043',
            shape: pillShapeNode,
        },
        label: 'Root',
        displayProperty: ['name'],
        query: {
            allow: true,
        },
        contextMenu: [
            {
                key: '1a',
                label: 'Cancel',
                action: 'cancelDrag',
            },
        ],
    },
    'omc:Root': {
        style: {
            color: '#F6DDCC',
            expand: '#E59866',
            dragTarget: '#FF0033',
            dragColor: '#FF7043',
            shape: pillShapeNode,
        },
        label: 'Root',
        displayProperty: ['name'],
        query: {
            allow: true,
        },
        contextMenu: null,
    },
    'skos:ConceptScheme': {
        style: {
            color: '#E8DAEF',
            expand: '#BB8FCE',
            dragTarget: '#9C27B0',
            dragColor: '#BA68C8',
            shape: pillShapeNode,
        },
        label: 'Scheme',
        displayProperty: ['name'],
        query: {
            allow: false,
        },
        contextMenu: null,
    },
    'skos:Concept': {
        style: {
            color: '#D4EFDF',
            expand: '#7DCEA0',
            dragTarget: '#2E7D32',
            dragColor: '#4CAF50',
            shape: pillShapeNode,
        },
        label: 'Concept',
        displayProperty: ['name'],
        query: {
            allow: true,
        },
        contextMenu: [
            {
                key: '1a',
                label: 'Move\xA0Concept',
                action: 'conceptMove',
            },
            {
                key: '1b',
                label: 'Remove\xA0Concept',
                action: 'conceptRemove',
            },
        ],
    },
};
