import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Box } from '@chakra-ui/react';

export default function Logo() {
    return (
        <Box>
            <Link to="/">
                <Image h="50px" w="50pz" src="/images/lab-coat.png" alt="LabKoat" />
            </Link>
        </Box>
    );
}
